<template>
  <el-card class="box-card">
    <div slot="header" class="tit">
      <h3 class="inBlock">订单详情</h3>
      <back></back>
    </div>
    <div class="box" v-if="this.detail != null">
      <div class="jindu">
        <el-steps align-center :active="active">
          <el-step
            :title="this.is_cancel == 1 ? '订单已取消' : '已下单'"
            icon="el-icon-s-order"
          ></el-step>
          <el-step
            :title="this.is_pay == 1 ? '已付款' : '未付款'"
            icon="el-icon-s-finance"
          ></el-step>
          <el-step
			v-if="!this.$route.query.type"
            :title="this.is_confirm == 1 ? '已自提' : '待自提'"
            icon="el-icon-s-cooperation"
          ></el-step>
		  <el-step
		  	v-else
		    :title="this.is_send == 1 ? '已发货' : '未发货'"
		    icon="el-icon-s-cooperation"
		  ></el-step>
          <el-step v-if="!this.$route.query.type" title="已完成" icon="el-icon-success"></el-step>
		  <el-step v-else :title="this.is_confirm == 1 ? '已完成' : '未收货'" icon="el-icon-success"></el-step>
        </el-steps>
      </div>
      <div class="content">
        <div class="left">
          <h3>订单编号: {{ detail[0].order_no }}</h3>
          <ul class="order">
            <!-- <li>
              <span>订单ID:</span><strong>{{ detail[0].id }}</strong>
            </li> -->
            <li>
              <span>下单时间:</span
              ><strong>{{ detail[0].addtime | datefilter }}</strong>
            </li>
            <li>
              <span>分佣金额:</span
              ><strong class="pay">{{ detail[0].agent_share_price }}</strong>
            </li>
            <li>
              <span>应付金额:</span
              ><strong class="pay">{{ detail[0].total_price }}</strong>
            </li>
            <li>
              <span>实付金额:</span
              ><strong class="pay">{{ detail[0].pay_price }}</strong>
            </li>
            <li>
              <span>运费:</span
              ><strong class="pay">{{ detail[0].express_price }}</strong>
            </li>
          </ul>
        </div>
        <div v-if="!this.$route.query.type" class="right">
          <h3>用户信息</h3>
          <ul class="order">
            <!-- <li>
              <span>用户id:</span><strong>{{ detail[0].store_user.id }}</strong>
            </li> -->
            <li>
              <span>用户名:</span
              ><strong>{{ detail[0].store_user.username }}</strong>
            </li>
            <li>
              <span>手机号:</span
              ><strong>{{ detail[0].store_user.mobile }}</strong>
            </li>
          </ul>
        </div>
		<div v-else class="right">
		  <h3>收货地址</h3>
		  <ul class="order">
		    <!-- <li>
		      <span>用户id:</span><strong>{{ detail[0].store_user.id }}</strong>
		    </li> -->
		    <li>
		      <span>收货人:</span
		      ><strong>{{ detail[0].name}}</strong>
		    </li>
		    <li>
		      <span>手机号:</span
		      ><strong>{{ detail[0].mobile}}</strong>
		    </li>
			<li>
			  <span>地区:</span
			  ><strong>{{ detail[0].address }}</strong>
			</li>
			<!-- <li>
			  <span>详细地址:</span
			  ><strong>{{ detail[0].store_user.mobile }}</strong>
			</li> -->
		  </ul>
		</div>
		
      </div>
      <div class="orderGoods">
        <h4>订单商品</h4>
        <div
          class="goodsDetail"
          v-for="item in detail[0].order_detail"
          :key="item.id"
        >
          <div class="img">
            <img :src="imgurl+item.pic" alt="" />
          </div>
          <div class="data">
            <p class="sheng">{{ item.goods.name }}</p>
            <p class="sheng">
              规格:
              <span v-if="item.attr != ''" style="margin-right: 20px">{{
                item.attr
              }}</span>
              <span v-else style="margin-right: 20px">默认</span>
              数量:<span>{{ item.num }}</span>
            </p>
            <p class="sheng">
              小计: <span>{{ item.total_price }}</span
              >元
            </p>
          </div>
          <div class="yongjin">
            <p>
              佣金额: <span>{{ item.agent_share_price }}</span
              >元
            </p>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { lookOrder } from "@/api/api.js";
import {getSystemsettings} from '../../api/getSystemsetting.js'
export default {
  components: {},
  name: "orderdetail",
  data() {
    return {
      detail: null,
      active: 0,
      is_cancel: 0,
      is_pay: 0,
	  is_send:0,
      is_confirm: 0,
	  imgurl:''
    };
  },
  methods: {
    async orderdetail(id) {
      const { data } = await lookOrder(id);
      if (data.code != 200) return this.$message.error(data.data);
      this.detail = data.data.order_detail.data;
      console.log(this.detail);
      this.getActive(this.detail[0]);
    },
    getActive(data) {
		console.log(data)
      this.is_cancel = data.is_cancel;
      this.is_pay = data.is_pay;
      this.is_confirm = data.is_confirm;
	  this.is_send=data.is_send
      if (this.is_cancel != 1) {
        this.active = this.is_cancel + 1 + this.is_pay + this.is_confirm;
      } else {
        this.active = 1;
      }

	  if (this.$route.query.type){
	  	if(this.is_send==1){
	  		this.active=3
	  	}
		if (this.is_confirm == 1) {
		  this.active =4;
		}
	  }else{
		  if (this.is_confirm == 1) {
		    this.active += 1;
		  }
	  }
	  
    },
  },
  created() {
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.orderdetail({ id: this.$route.query.id });
    }
	if (this.$route.query.type){
		
	}
	getSystemsettings.then(res=>{
		this.imgurl=res
	})
	
  },
};
</script>

<style scoped lang='less'>
.box {
  .jindu {
    border: 1px solid #f1f1f1;
    margin-bottom: 10px;
    padding: 80px 20px;
  }
  .content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    
  }
  .order {
    border: 1px solid #f1f1f1;
    width: 700px;
    margin-top: 20px;
    li {
      &:nth-child(2n) {
        background-color: #eee;
      }
      span {
        text-align: right;
        padding: 10px 5px 10px 0;
        display: inline-block;
        width: 100px;
        border-right: 1px solid #eee;
      }
      strong {
        padding: 10px 0px 10px 5px;
      }
    }
  }
}
.pay {
  color: red;
}
.orderGoods {
  border: 1px solid #f1f1f1;
  padding: 10px;
  margin-top: 20px;
  h4 {
    margin: 0px 0 10px 15px;
    font-weight: normal;
    font-size: 18px;
  }
  .goodsDetail {
    background-color: #f1f3f4;
    margin-right: 50px;
    padding: 10px;
    margin-bottom: 5px;
    display: flex;
    .img {
      width: 77px;
      height: 77px;
      background-color: #e9ebec;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
    .data {
      p {
        &:nth-child(2) {
          margin: 15px 0;
        }
        span {
          color: #db5376;
        }
      }
    }
    .yongjin {
      display: flex;
      align-items: center;
      margin-left: 200px;
      span {
        color: red;
        margin-left: 10px;
      }
    }
  }
}
</style>